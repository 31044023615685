import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NzMessageService } from "ng-zorro-antd/message";
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap } from "rxjs";
import { SeasonsApiService } from "../seasons-api.service";
import { SeasonsActions } from "./seasons.actions";

@Injectable()
export class SeasonsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: SeasonsApiService,
    private readonly messageService: NzMessageService
  ) {}

  seasonFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeasonsActions.seasonFetchById),
      mergeMap((request) =>
        this.apiService
          .getSeasonById(request.seasonId)
          .pipe(
            map((season) => SeasonsActions.seasonFetchByIdSuccess({ season }))
          )
      )
    )
  );

  seasonFetchUserEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeasonsActions.seasonEntryFetch),
      switchMap((request) =>
        this.apiService.getSeasonEntry(request.seasonId).pipe(
          map((entrant) => SeasonsActions.seasonEntryFetchSuccess({ entrant })),
          catchError(() =>
            of(
              SeasonsActions.seasonEntryFetchFailure({
                seasonId: request.seasonId,
              })
            )
          )
        )
      )
    )
  );

  seasonsListFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeasonsActions.seasonsFetch),
      mergeMap(() =>
        this.apiService.getAllSeasons().pipe(
          map((seasons) => SeasonsActions.seasonsFetchSuccess({ seasons })),
          catchError(() => of(SeasonsActions.seasonsFetchFailure({})))
        )
      )
    )
  );

  seasonsListFetchFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SeasonsActions.seasonsFetchFailure),
        map(() => EMPTY),
        tap(() =>
          this.messageService.error("Pobranie listy Wydarzeń nie powiodło się!")
        )
      ),
    {
      dispatch: false,
    }
  );
}
