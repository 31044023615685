import { createReducer, on } from "@ngrx/store";
import { GalleryCategoryView } from "../model";
import { GalleryApiActions } from "./gallery.actions";

export const galleryStore: Record<number, GalleryCategoryView> = {};

export const galleryReducer = createReducer(
  galleryStore,
  on(GalleryApiActions.galleryCategoriesFetchAllSuccess, (state, effect) => {
    const result = { ...state };
    effect.galleries.forEach((gallery) => {
      result[gallery.id] = gallery;
    });
    return result;
  }),
  on(GalleryApiActions.galleryCategoryUpdate, (state, effect) => {
    return { ...state, [effect.gallery.id]: effect.gallery };
  }),
  on(GalleryApiActions.galleryCategoryFetchByIdSuccess, (state, effect) => {
    return { ...state, [effect.gallery.id]: effect.gallery };
  }),
  on(GalleryApiActions.galleryFetchBySeasonIdSuccess, (state, effect) => {
    const store = structuredClone(galleryStore);
    effect.galleries.forEach((gallery) => {
      store[gallery.id] = gallery;
    });
    return store;
  })
);
