import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs";
import { User } from "../model";
import { UsersService } from "../users.service";
import { UsersApiActions } from "./users.actions";

@Injectable()
export class UsersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly restService: UsersService
  ) {}

  userFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersApiActions.userFetchByID),
      mergeMap((request) => this.restService.getUserById(request.id)),
      map((response: User) =>
        UsersApiActions.userFetchByIDSuccess({ user: response })
      )
    )
  );
}
