import { HttpInterceptor as BaseInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, exhaustMap, first, map, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class HttpInterceptor implements BaseInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        "page-id": `${environment.pageId}`,
      },
    });

    return this.authService.isAuthenticated$.pipe(
      first(),
      switchMap((authenticated) =>
        authenticated ? this.authService.getToken() : of(null)
      ),
      map((token) =>
        token
          ? request.clone({
              setHeaders: { token },
            })
          : request
      ),
      exhaustMap((request) => next.handle(request))
    );
  }
}
