import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NzMessageService } from "ng-zorro-antd/message";
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap } from "rxjs";
import { GalleryRestService } from "../gallery-rest.service";
import { GalleryApiActions } from "./gallery.actions";

@Injectable()
export class GalleryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly restService: GalleryRestService,
    private readonly messageService: NzMessageService
  ) {}

  fetchAllGalleries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GalleryApiActions.galleryCategoriesFetchAll),
      switchMap(() => this.restService.listAllGalleriesWithHidden()),
      map((galleries) =>
        GalleryApiActions.galleryCategoriesFetchAllSuccess({ galleries })
      )
    )
  );

  changeGalleryVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GalleryApiActions.galleryCategoryChangeVisibility),
      switchMap((props) =>
        this.restService
          .changeGalleryVisibility(props.galleryId, props.visibility)
          .pipe(
            map((gallery) =>
              GalleryApiActions.galleryCategoryUpdate({ gallery })
            )
          )
      ),
      tap(() => this.messageService.success("Zmiany zostały zapisane"))
    )
  );

  changeGalleryOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GalleryApiActions.galleryCategoryChangeOrder),
      switchMap((props) =>
        this.restService
          .changeGalleryOrder(props.galleryId, props.order)
          .pipe(
            map((galleries) =>
              GalleryApiActions.galleryCategoriesFetchAllSuccess({ galleries })
            )
          )
      ),
      tap(() => this.messageService.success("Zmiany zostały zapisane"))
    )
  );

  galleryFetchBySeasonId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GalleryApiActions.galleryFetchBySeasonId),
      mergeMap((action) =>
        this.restService.findGalleryBySeasonId(action.seasonId).pipe(
          map((galleries) =>
            GalleryApiActions.galleryFetchBySeasonIdSuccess({ galleries })
          ),
          catchError(() =>
            of(GalleryApiActions.galleryFetchBySeasonIdFailure({}))
          )
        )
      )
    )
  );

  galleryFetchBySeasonIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GalleryApiActions.galleryFetchBySeasonIdFailure),
        map(() => EMPTY),
        tap(() =>
          this.messageService.error("Wystąpił błąd przy pobieraniu galerii!")
        )
      ),
    {
      dispatch: false,
    }
  );
}
