import { APP_INITIALIZER, isDevMode, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { registerLocaleData } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import pl from "@angular/common/locales/pl";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { EventsEffects } from "@api/event/state/event.effects";
import { eventsReducer } from "@api/event/state/event.reducer";
import { GalleryEffects } from "@api/gallery/state/gallery.effects";
import { galleryReducer } from "@api/gallery/state/gallery.reducer";
import { SeasonsEffects } from "@api/seasons/state/seasons.effects";
import { seasonsReducer } from "@api/seasons/state/seasons.reducer";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AuthEffects } from "@shared/services/auth/state/auth.effects";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NZ_I18N, pl_PL } from "ng-zorro-antd/i18n";
import { NzSpinModule } from "ng-zorro-antd/spin";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { QuillModule } from "ngx-quill";
import { first, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { UsersApiActions } from "./api/users/state/users.actions";
import { UsersEffects } from "./api/users/state/users.effects";
import { usersReducer } from "./api/users/state/users.reducer";
import { UsersService } from "./api/users/users.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./modules/core/core.module";
import { ConfigService } from "./modules/core/services/config/config.service";
import { LeagueModule } from "./modules/league/league.module";
import { AuthService } from "./shared/services/auth/auth.service";
import { authReducer } from "./shared/services/auth/state/auth.reducer";
import { HttpInterceptor } from "./shared/services/http-interceptor/http-interceptor";

registerLocaleData(pl);

export function initApp(auth: AuthService) {
  return () => {
    // user.loadConfiguration();
    auth.loadConfiguration();
  };
}

export function initUsers(usersService: UsersService, store: Store) {
  return () =>
    usersService.getUsers().pipe(
      first(),
      tap((users) =>
        store.dispatch(UsersApiActions.usersFetchSuccess({ users }))
      )
    );
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NzSpinModule,
    CoreModule,
    LeagueModule,
    NzDividerModule,
    RouterModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
    NgxGoogleAnalyticsRouterModule,
    StoreModule.forRoot({
      auth: authReducer,
      users: usersReducer,
      events: eventsReducer,
      seasons: seasonsReducer,
      galleries: galleryReducer,
    }),
    EffectsModule.forRoot([
      UsersEffects,
      AuthEffects,
      EventsEffects,
      SeasonsEffects,
      GalleryEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: isDevMode(), //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true,
    }),
    QuillModule.forRoot(),
    NgbModule,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initUsers,
      deps: [UsersService, Store],
      multi: true,
    },
    { provide: NZ_I18N, useValue: pl_PL },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
      deps: [AuthService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
