import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  configLoaded = true;

  readonly staticUrl = environment.staticUrl;
  readonly production = environment.production;

  readonly now = new Date().getTime();

  ngOnInit() {
    console.log(
      `%cUśmiech!\n\u00a9 Porsche ${new Date().getFullYear()}`,
      "background: #d5001c; color: #e1e1e1"
    );
  }
}
