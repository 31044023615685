@if (!production || true) {
<app-loader *ngIf="!configLoaded"></app-loader>
<div class="main-container" *ngIf="configLoaded">
  <router-outlet></router-outlet>
</div>
}
@else {
<div class="cover-container">
  <img class="coming-soon d-none d-md-block" alt="Cover up"
    [src]="staticUrl + 'porscheesports/2024/pescp-2024-cover.webp?cache=' + now" />
  <img class="coming-soon d-md-none" alt="Cover up mobile"
    [src]="staticUrl + 'porscheesports/2024/pescp-2024-cover-mobile.webp?cache=' + now" />
</div>
}